<template>
    <div v-if="order">
        <b-row v-if="!isConfirmingPreOrder" class="mb-3">
            <b-col sm="6" md="4" xl="3">
                <span>{{ t('ordernumber') }}</span>
            </b-col>
            <b-col sm="6" md="8" xl="9">
                <span class="font-weight-bold">{{ get(order, 'orderNumber') }}</span>
            </b-col>
        </b-row>
        <b-row v-if="!isConfirmingPreOrder" class="mb-3">
            <b-col sm="6" md="4" xl="3">
                <span>{{ t('orderType') }}</span>
            </b-col>
            <b-col sm="6" md="8" xl="9">
                <span class="font-weight-bold">{{
                    order.orderTypeName || this.t('notAvailable')
                }}</span></b-col
            >
        </b-row>
        <b-row class="mb-3">
            <b-col sm="6" md="4" xl="3">
                <span>{{ t(isConfirmingPreOrder ? 'checkout_PreOrderDate' : 'orderDate') }}</span>
            </b-col>
            <b-col sm="6" md="8" xl="9">
                <span class="font-weight-bold">{{ get(order, 'orderDate') | date }}</span>
            </b-col>
        </b-row>
        <b-row v-if="isConfirmingPreOrder" class="mb-3">
            <b-col sm="6" md="4" xl="3">
                <span>{{ t('expirationDate') }}</span>
            </b-col>
            <b-col sm="6" md="8" xl="9">
                <span class="font-weight-bold">{{ get(order, 'expirationDate') | date }}</span>
            </b-col>
        </b-row>
        <b-row class="mb-3">
            <b-col sm="6" md="4" xl="3">
                <span>{{ t('checkout_Customer') }}</span>
            </b-col>
            <b-col sm="6" md="8" xl="9">
                <span class="font-weight-bold">{{ get(order, 'customer') }}</span>
                <span v-if="get(order, 'ocosCustomerId')" class="text-muted"
                    >({{ get(order, 'ocosCustomerId') }})</span
                >
            </b-col>
        </b-row>
        <b-row class="mb-5">
            <b-col sm="6" md="4" xl="3">
                <span>{{
                    t(isConfirmingPreOrder ? 'checkout_PreOrderStatus' : 'statusNormalCase')
                }}</span>
            </b-col>
            <b-col sm="6" md="8" xl="9">
                <span class="font-weight-bold">{{ orderStatus }}</span>
            </b-col>
        </b-row>
        <b-row v-if="get(order, 'shippingDetails.deliveryInstructions')" class="mb-3">
            <b-col sm="12">
                <span class="font-weight-bold">{{ t('checkout_DeliveryInstructions') }}</span>
            </b-col>
            <b-col sm="12">
                <span>{{ get(order, 'shippingDetails.deliveryInstructions', '') }}</span>
            </b-col>
        </b-row>
        <b-row v-if="get(order, 'billingDetails.billingInstructions')" class="mb-3">
            <b-col sm="12">
                <span class="font-weight-bold">{{ t('checkout_BillingInstructions') }}</span>
            </b-col>
            <b-col sm="12">
                <span>{{ get(order, 'billingDetails.billingInstructions', '') }}</span>
            </b-col>
        </b-row>
        <b-row v-if="get(order, 'paymentDetails.paymentDetailsNote')" class="mb-5">
            <b-col sm="12">
                <span class="font-weight-bold">{{ t('checkout_PaymentNotes') }}</span>
            </b-col>
            <b-col sm="12">
                <span>{{ get(order, 'paymentDetails.paymentDetailsNote', '') }}</span>
            </b-col>
        </b-row>
        <b-row
            v-if="hasShipBillToPermission && !isConfirmingPreOrder && !isConsignment"
            class="mb-3"
        >
            <b-col md="12">
                <span class="d-block mb-1 font-weight-bold">{{
                    t('checkout_ShippingAddress')
                }}</span>
                <span class="d-block mb-1">{{ shippingDetailsAddress }}</span>
                <div v-if="currentZone === ZoneCodes.US">
                    <span class="d-block mb-1"
                        >{{ get(order, 'shippingDetails.city', '') }}
                        {{ get(order, 'shippingDetails.state', '') }}
                        {{ get(order, 'shippingDetails.zip', '') }}</span
                    >
                    <span class="d-block mb-1">{{
                        get(order, 'shippingDetails.country', '')
                    }}</span>
                </div>
                <div v-if="currentZone === ZoneCodes.OUS">
                    <span class="d-block mb-1"
                        >{{ get(order, 'shippingDetails.city', '') }}
                        {{ get(order, 'shippingDetails.country', '') }}</span
                    >
                    <span class="d-block mb-1">{{ get(order, 'shippingDetails.zip', '') }}</span>
                </div>
                <span class="d-block mb-1">{{
                    get(order, 'shippingDetails.phoneNumber', '')
                }}</span>
                <span v-if="get(order, 'shippingDetails.email')" class="d-block pb-3">
                    <a :href="`mailto:${get(order, 'shippingDetails.email', '')}`">{{
                        get(order, 'shippingDetails.email', '')
                    }}</a>
                </span>
            </b-col>
        </b-row>
        <b-row v-if="!isConfirmingPreOrder" class="mb-5">
            <b-col v-if="hasShipBillToPermission" md="6">
                <span class="d-block mb-1 font-weight-bold">{{
                    t('checkout_BillingAddress')
                }}</span>
                <span class="d-block mb-1">{{ get(billingDetails, 'address', '') }}</span>
                <div v-if="currentZone === ZoneCodes.US">
                    <span class="d-block mb-1"
                        >{{ get(billingDetails, 'city', '') }}
                        {{ get(billingDetails, 'state', '') }}
                        {{
                            isConsignment
                                ? get(billingDetails, 'zipCode', '')
                                : get(billingDetails, 'zip', '')
                        }}</span
                    >
                    <span class="d-block mb-1">{{ get(billingDetails, 'country', '') }}</span>
                </div>
                <div v-if="currentZone === ZoneCodes.OUS">
                    <span class="d-block mb-1"
                        >{{ get(billingDetails, 'city', '') }}
                        {{ get(billingDetails, 'country', '') }}</span
                    >
                    <span class="d-block mb-1">{{ get(billingDetails, 'zip', '') }}</span>
                </div>
                <span class="d-block mb-1">{{ get(billingDetails, 'phoneNumber', '') }}</span>
                <span v-if="get(billingDetails, 'email')" class="d-block pb-3">
                    <a :href="`mailto:${get(billingDetails, 'email', '')}`">{{
                        get(billingDetails, 'email', '')
                    }}</a>
                </span>
            </b-col>
            <b-col md="6" v-if="get(order, 'paymentDetails.purchaseOrderNumber', '')">
                <span class="d-block mb-2 font-weight-bold">{{ t('checkout_PaymentMethod') }}</span>
                <span class="d-block mb-1">{{ t('checkout_PurchaseNumber') }}:</span>
                <span class="d-block font-weight-bold">{{
                    get(order, 'paymentDetails.purchaseOrderNumber', '')
                }}</span>
            </b-col>
        </b-row>
        <div class="border-divider pb-5">
            <div v-if="isConfirmingPreOrder">
                <div
                    class="h4"
                    v-html="t('cancelPreOrderNote', {customerName: get(activeCustomer, 'name')})"
                ></div>
                <br />
            </div>
            <h4 class="h4 mb-2 border-divider font-weight-bold">
                {{
                    t(
                        isConfirmingPreOrder
                            ? 'reservationDetails_LensInPreOrder'
                            : 'checkout_ItemInOrder'
                    )
                }}
            </h4>
            <div v-for="lensGroup in lensGroups" :key="lensGroup.patientId" class="lens-group">
                <h5 class="text-center my-3">
                    {{ t('checkout_Patient') }}:
                    <custom-router-link
                        :to="{name: 'PatientDetails', params: {patientId: lensGroup.patientId}}"
                        class="text-primary font-weight-bold"
                        ><u>{{ lensGroup.patientOcosId }}</u></custom-router-link
                    >
                </h5>
                <LensCard
                    v-for="lens in lensGroup.items"
                    :key="lens.orderItemId"
                    :lens="lens"
                    :readonly="true"
                    :showPrimaryLensStatus="get(activeCustomer, 'maxLenses') > 1"
                    :additionalLensStatusKeyText="`checkout_AdditionalLensStatus_${currentZone}`"
                    :is-confirm-buttton-disabled="!returnReasonId"
                    remove-prompt-key-text="orderConfirmation_ReturnLensPrompt"
                    @on-remove="returnLens"
                >
                    <template
                        v-if="!lens.isMto && !isConfirmingPreOrder"
                        v-slot:custom-actions-before
                    >
                        <div class="custom-actions text-center">
                            <b-button
                                @click="iodDownload(lens)"
                                variant="outline-primary"
                                class="mt-1"
                                >{{ isSidButton(lens) }}</b-button
                            >
                        </div>
                    </template>
                    <template
                        v-if="hasOrderingPermission && !isConfirmingPreOrder && false"
                        v-slot:custom-actions-after="{props}"
                    >
                        <h6 v-if="lens.returnRequested" class="mt-4 text-center min-w-106px">
                            {{ t('returnRequested') }} <br />{{ lens.returnReason }} <br />{{
                                lens.returnRequested | date
                            }}
                        </h6>
                        <div v-else class="custom-actions text-center min-w-106px">
                            <img
                                class="pointer"
                                width="40"
                                height="40"
                                src="@/assets/shopping-cart/back-box.svg"
                                @click="props.showRemovePrompt = true"
                            />
                        </div>
                    </template>
                    <template v-slot:remove-prompt-after-card="{props}">
                        <b-card class="mt-3">
                            <b-form-group
                                :label="t('selectReason')"
                                label-align="left"
                                label-size="lg"
                            >
                                <b-form-select
                                    :options="returnReasons"
                                    v-model="returnReasonId"
                                    text-field="reason"
                                    value-field="returnReasonId"
                                    size="sm"
                                    @change="onSelectReason($event, props)"
                                >
                                    <template #first>
                                        <b-form-select-option :value="null">{{
                                            t('select')
                                        }}</b-form-select-option>
                                    </template>
                                </b-form-select>
                            </b-form-group>
                        </b-card>
                    </template>
                </LensCard>
            </div>
            <template v-if="!isConfirmingPreOrder">
                <AccesoryCard
                    v-for="accessory in accessories"
                    :key="accessory.orderItemId"
                    :accessory="accessory"
                    :readonly="true"
                />
            </template>
        </div>
        <b-row class="mt-5 mb-5 pb-5 text-center text-sm-left">
            <b-col
                sm="6"
                class="align-self-center"
                v-if="hasPricingPermission && !isDistributorSurgeonMode"
            >
                <span class="h4 mr-2">{{ t('checkout_OrderTotal') }}</span>
                <span class="h3 font-weight-bold">{{ orderTotal | toCurrency }}</span>
            </b-col>
            <b-col sm="6">
                <b-button
                    v-show="false"
                    @click="notImplemented"
                    size="lg"
                    variant="primary"
                    class="btn-block-xs-only float-right"
                    >{{ t('checkout_TrackPackage') }}</b-button
                >
            </b-col>
        </b-row>
    </div>
</template>

<script>
import get from 'lodash/get';
import first from 'lodash/first';
import LensCard from '@/components/LensCard.vue';
import AccesoryCard from '@/components/AccesoryCard.vue';
import {ZoneCodes} from '@/constants/zone';
import {InventorySource} from '@/constants/inventory';
import {getReservationOrderStatus} from '@/utilities/formatters';

export default {
    components: {LensCard, AccesoryCard},
    props: {
        order: {
            type: Object,
            required: true,
        },
        isConfirmingPreOrder: {
            type: Boolean,
            default: false,
        },
        activeCustomer: {
            type: Object,
            required: true,
        },
        currentZone: {
            type: String,
            required: true,
        },
        returnReasons: {
            type: Array,
            required: true,
        },
        returnReasonId: {
            type: Number,
            required: false,
        },
        hasOrderingPermission: {
            type: Boolean,
            required: true,
        },
        hasPricingPermission: {
            type: Boolean,
            required: true,
        },
        hasShipBillToPermission: {
            type: Boolean,
            required: true,
        },
        isDistributorSurgeonMode: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            ZoneCodes,
        };
    },
    methods: {
        get,
        iodDownload(lens) {
            this.$emit('iod-download', lens);
        },
        isSidButton(lensDetail) {
            return get(lensDetail, 'isToric') ? 'IOD' : 'SID';
        },
        onSelectReason(returnReasonId, props) {
            if (returnReasonId !== -1) return;
            this.$emit('show-complaint-reason-msg', true);
            this.returnReasonId = null;
        },
        returnLens(lens) {
            const orderId = get(this.order, 'orderHeaderId', '');
            this.$emit('return-lens', orderId, lens);
        },
        getReservationOrderStatus,
    },
    computed: {
        lensGroups() {
            return get(this.order, 'lensItemsByPatientId', []);
        },
        accessories() {
            return (get(this.order, 'items', []) || [])
                .filter((item) => get(item, 'itemType') === 'ACCESSORY')
                .map((item) => ({
                    ...item,
                    quantityWrapper: get(item, 'quantity').toLocaleString('en-US'),
                }))
                .sort((a, b) => get(b, 'automaticallyAdded') - get(a, 'automaticallyAdded'));
        },
        shippingDetailsAddress() {
            const address = get(this.order, 'shippingDetails.address', '');
            try {
                const addressArray = JSON.parse(address);
                return addressArray.join(', ');
            } catch (e) {
                return address;
            }
        },
        billingDetails() {
            return this.isConsignment ? this.activeCustomer : this.order.billingDetails;
        },
        orderStatus() {
            const subStatus = get(this.order, 'orderSubStatus');
            const status = subStatus || get(this.order, 'orderStatus');

            return this.getReservationOrderStatus(status, this.isConsignment);
        },
        orderTotal() {
            return (get(this.order, 'items', []) || []).reduce(
                (acc, item) => acc + get(item, 'price') * get(item, 'quantity'),
                0
            );
        },
        isConsignment() {
            const lensItems = get(first(this.lensGroups), 'items', []);
            const lens = first(lensItems);

            return !!lens && lens.lensSourceId == InventorySource.CONSIGNMENT;
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../../../assets/css/variables';

.border-divider {
    border-bottom: 3px solid #91979d;
    padding-bottom: 30px;
}
.min-w-106px {
    min-width: 106px;
}
.lens-group {
    border-bottom: 3px solid $gray-dark;
    &:last-child {
        border-bottom: 0px;
    }
}
</style>
